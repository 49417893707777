import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { List, ListItem, Stack } from "@kiwicom/orbit-components"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Concession Card Reader">
    <Hero title="Concession Card Reader" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard>
          <EmberCardSection title="Introduction">
            <Stack>
              <Text>
                The tech team has build a replacement for the existing
                concession card reader. There's two parts to it: a minature
                computer in an orange box hidden inside the dashboard (called
                the "Bus Server) and the actual card reader which is attached
                onto the dashboard.
              </Text>
              <Text>
                The new reader has a screen facing passengers providing a
                graphic showing that the card has scanned properly along side
                the existing beep noises. It integrates with the iPad, like the
                existing reader, but has a few differences to be aware of which
                are explained on this page.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard>
          <EmberCardSection title="Connecting to the Bus Server">
            <Stack>
              <Text>
                To use the reader, the iPad must be connected to the Bus Server
                via Bluetooth. If you see a warning that it is not connected
                then here is what to do:
              </Text>
              <List>
                <ListItem>
                  Tap "More" or "..." on the top-right of the driver app to open
                  the settings menu.
                </ListItem>
                <ListItem>Select Bus Server from the list.</ListItem>
                <ListItem>
                  You'll see a list of nearby Bus Servers. While the iPad is
                  searching, you will see a random 6 character string
                  representing each one. You should always connect to the bus
                  server which is showing the strongest signal, as that is the
                  one most likely to be on your bus.
                </ListItem>
                <ListItem>
                  If you connect to a bus server on a different vehicle, you
                  will not be able to scan concession cards.
                </ListItem>
                <StaticImage
                  src="./images/scanning.jpeg"
                  alt="While scanning"
                />
                <ListItem>
                  Once connected, you will see a proper name for the Bus Server.
                  These tend to have a silly name such as Mercury Octopus, or
                  Iron Blobfish.
                </ListItem>
                <StaticImage
                  src="./images/connected.jpeg"
                  alt="Once connected"
                />
              </List>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard>
          <EmberCardSection title="Passenger Facing Screen">
            <Stack>
              <Text>
                The reader itself has a screen facing passengers. You won't be
                able to see this screen from the driver's seat but it's worth
                knowing roughly what will be showing in case a customer asks.
              </Text>
              <Text>
                The reader will be ready to scan when you open a passenger's
                pass on the iPad, or when you start on board sale. They'll see
                the screen below indicating that they can tap their card:
              </Text>
              <StaticImage
                src="./images/ready_to_scan.jpg"
                alt="Ready to Scan Screen"
              />
              <Text>
                If their concession pass is permitted for travel, the passenger
                will see this green tick and hear an audible beep as below:
              </Text>
              <StaticImage
                src="./images/accepted.jpg"
                alt="Pass Accepted Screen"
              />
              <Text>
                If their concession pass is not permitted for travel, the
                passenger will see this red cross and hear an audible beep. You
                should also see a reason for why they cannot use their pass on
                the iPad.
              </Text>
              <StaticImage
                src="./images/not_accepted.jpg"
                alt="Pass Not Accepted Screen"
              />
              <Text>
                The passenger will see this stern looking figure when they are
                to not scan their card. This is a change from the existing
                concession reader where a passenger can immediately board and
                tap before you have selected/scanned their pass. You must now
                open the pass before the passenger can tap their concession
                card.
              </Text>
              <StaticImage
                src="./images/please_wait.jpg"
                alt="Please Wait Screen"
              />
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard>
          <EmberCardSection title="Companion Cards">
            <Stack>
              <Text>
                There's a new screen which will pop up on the iPad for
                passengers who have a card that allows them to travel with a
                companion for free. This allows you to confirm with the
                passenger whether they are travelling with a companion or not.
              </Text>
              <Text>
                You'll only see this pop up when there is space on the booking
                for a companion, so if there is just one concession ticket
                booked and the passenger taps their concession card with a
                companion, then this will not display.
              </Text>
              <StaticImage
                src="./images/companion-sheet.jpg"
                alt="Companion Sheet"
              />
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard>
          <EmberCardSection title="Bus Server Bootup">
            <Stack>
              <Text>
                The bus server may restart itself sometimes while a bus is
                parked up & on charge, or after it has been isolated. Before the
                concession card reader is ready for use, you'll see the
                following screen. Note the "Please Wait" text and icon, without
                displaying the Ember Logo.
              </Text>
              <StaticImage
                src="./images/bootup_screen.jpg"
                alt="While bootuing"
              />
              <Text>
                It takes around 1½ - 2 minutes for the whole thing to power and
                and start up. Once it's ready, you'll see this blank screen with
                the Ember logo in the lower left corner.
              </Text>
              <StaticImage src="./images/post_boot.jpg" alt="After bootup" />
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
